import React from "react"

import Layout from "../../components/layout.tsx"

import FeedbackCard from "../../components/feedback_card.tsx"
import AboutUs from "../../components/about_us.tsx"

const BootlegProject = ({ data, location }) => {
  return (
    <Layout location={location} title="Bootleg Token">
      <div className="bootleg-project-hero" />
      <div className="lg:w-1/2 mx-16 lg:ml-12 -mt-20">
        <h1>Bootleg</h1>
        <p className="lead">
          A project concieved and created as part of the{" "}
          <a
            href="https://mesh.xyz/web3studio/"
            target="_blank"
            rel="noreferrer"
          >
            Web3Studio team
          </a>{" "}
          at{" "}
          <a href="https://consensys.net/" target="_blank" rel="noreferrer">
            ConsenSys
          </a>
          . It was a token and a royalty payment system for artists to monitize
          concert footage captured by their fans.
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 my-8 lg:mx-auto mt-16 text-black">
        <h3>A fully functional Proof-of-Concept</h3>
        <p>
          While the project only ran for a few months we were able to make some
          notable firsts. We were the first project to make use of NFTs to
          unlock custom media content. The content was encrypted with the NFT
          owners public key and stored on IPFS. We were also the first to
          develop and deploy the novel &quot;Shared Royalty NFT&quot; (a.k.a a
          SRNFT). This unique model rewarded anyone who purchased and then sold
          the NFT. After our token launch we captured a live artist performance
          at the SXSW 2019 music festival. The first ever Bootleg token was
          minted and sold shortly there after. The artist and all previous
          holders received a percentage of the sales, with the majority of the
          Eth going to the artist.
        </p>
        <p>
          If you are interested in reading more, all the project details and
          code are open source.
        </p>
        <h3>For more information</h3>
        <ul className="list-inside p-2 list-disc">
          <li>
            <a
              href="https://mesh.xyz/web3studio/bootleg/"
              target="_blank"
              rel="noreferrer"
            >
              The original project page
            </a>
          </li>
          <li>
            <a
              href="https://github.com/ConsenSysMesh/web3studio-bootleg"
              target="_blank"
              rel="noreferrer"
            >
              All the source code for the contracts, token selling/trading dapp,
              etc...
            </a>
          </li>
          <li>
            <a
              href="https://etherscan.io/token/0xEe9f2af26ec8E8B1aD63ECDAeb31A6A08458E048"
              target="_blank"
              rel="noreferrer"
            >
              The BLEG token and contract on Ethereum main-net
            </a>
          </li>
          <li>
            <a
              href="https://decrypt.co/5824/bootleg-shared-royalty-token-aims-reconnect-artists-fans"
              target="_blank"
              rel="noreferrer"
            >
              Some press about the project
            </a>
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 lg:ml-auto">
          <FeedbackCard location="projects/bootleg" />
        </div>
        <div className="mt-8 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default BootlegProject
